<template>
  <Layout>
    <div class="mb-3">
      <div class="ride-steps mb-4">
        <div class="ride-step active">
          <div class="ride-step-index">1</div>
          <div class="ride-step-title">Choose Route</div>
        </div>
        <div class="ride-step">
          <div class="ride-step-index">2</div>
          <div class="ride-step-title">Select Company</div>
        </div>
        <div class="ride-step">
          <div class="ride-step-index">3</div>
          <div class="ride-step-title">Payment</div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6 col-xl-4 mb-3 mb-md-0">
          <h3 class="header-title">Route</h3>

          <b-alert
            :variant="msg.type"
            dismissible
            v-model="msg.has"
            :show="msg.text"
            >{{ msg.text }}</b-alert
          >

          <div class="card">
            <div class="card-body">
              <draggable v-model="waypointFields" handle=".handle">
                <transition-group>
                  <div
                    class="form-group"
                    :class="[i === waypointFields.length - 1 ? 'mb-0' : 'mb-2']"
                    v-for="(w, i) in waypointFields"
                    :key="w.id"
                  >
                    <label class="d-block handle">
                      <template v-if="i === 0">
                        Start Location {{ setPlaceLetter(w.id) }}:
                      </template>
                      <template v-else-if="i === waypointFields.length - 1">
                        End Location {{ setPlaceLetter(w.id) }}:
                      </template>
                      <template v-else>
                        Transit Location {{ setPlaceLetter(w.id) }}:
                      </template>
                    </label>

                    <div
                      class="input-group input-group-merge input-with-control-ico"
                    >
                      <div class="position-relative w-100">
                        <GmapAutocomplete
                          @place_changed="setPlace($event, w.id)"
                          :componentRestrictions="componentRestrictions"
                          :selectFirstOnEnter="true"
                          :setFieldsTo="mapFields"
                          :ref="`transitPoint-${w.id}`"
                          class="form-control form-autocomplete-place"
                        />
                        <span
                          v-if="w.point"
                          @click="resetPlace(w.id)"
                          :disabled="wait"
                          title="Reset place"
                          class="material-symbols-rounded input-with-control-ico-btn"
                        >
                          close
                        </span>
                      </div>
                      <a
                        class="btn btn-outline-primary btn-ico ml-2"
                        :class="{ disabled: wait }"
                        v-if="
                          i === 0 && waypointFields.length < maxWaypointFields
                        "
                        @click.prevent="addWaypointField"
                        title="Add location"
                        href="#"
                      >
                        <span class="material-symbols-rounded">
                          add_location
                        </span>
                      </a>
                      <a
                        v-if="i > 0 && waypointFields.length > 2"
                        class="btn btn-transparent btn-ico btn-ico-remove ml-1"
                        :class="{ disabled: wait }"
                        @click.prevent="removeWaypointField(w.id)"
                        title="Remove location"
                        href="#"
                      >
                        <span class="material-symbols-rounded"> cancel </span>
                      </a>
                    </div>
                  </div>
                </transition-group>
              </draggable>
            </div>
          </div>

          <h3 class="header-title">Additional info</h3>

          <div class="card">
            <div class="card-body">
              <!-- <pre>orderpresets: {{ orderpresets }}</pre> -->
              <div v-if="authorized" class="form-group mb-2">
                <label>Ride preset</label>
                <div class="input-group input-group-merge">
                  <multiselect
                    v-model="orderpreset"
                    :options="orderpresets"
                    track-by="id"
                    label="title"
                    :multiple="false"
                    :allow-empty="true"
                    :maxHeight="240"
                    tag-placeholder=""
                    placeholder="Select ride preset"
                    class="w-100"
                  />
                </div>
              </div>

              <div class="row">
                <div class="col-6">
                  <div class="form-group mb-2">
                    <label>Pickup date</label>
                    <div class="input-group input-group-merge">
                      <date-picker
                        v-model="form.pickup_date"
                        :lang="datepickerConfig"
                        placeholder="MM-DD-YYYY"
                        format="MM-DD-YYYY"
                        input-class="form-control"
                        :disabled-date="disabledDate"
                        :class="{
                          'is-invalid': submitted && $v.form.pickup_date.$error,
                        }"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group mb-2">
                    <label>Pickup time</label>
                    <div class="input-group input-group-merge">
                      <date-picker
                        v-model="form.pickup_time"
                        :lang="datepickerConfig"
                        type="time"
                        format="hh:mm a"
                        placeholder="hh:mm a"
                        use12h
                        input-class="form-control"
                        :disabled="!form.pickup_date"
                        :disabled-time="disabledTime"
                        :class="{
                          'is-invalid': submitted && $v.form.pickup_time.$error,
                        }"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group mt-1 mb-2">
                <div class="input-group">
                  <b-form-checkbox
                    v-model="form.roundtrip"
                    value="1"
                    unchecked-value="0"
                  >
                    Round Trip
                  </b-form-checkbox>
                </div>
              </div>

              <div class="form-group mb-2">
                <label>Who will be riding</label>
                <div class="input-group">
                  <b-form-radio
                    v-model="form.whoride"
                    name="whoride-radios"
                    :value="1"
                    class="mr-3"
                    @change="loadUserParam"
                    >Me</b-form-radio
                  >
                  <b-form-radio
                    v-model="form.whoride"
                    name="whoride-radios"
                    :value="2"
                    @change="resetParam"
                    >Someone else</b-form-radio
                  >
                </div>
              </div>

              <template v-if="form.whoride === 2">
                <div class="form-group mb-2">
                  <label for="ride-cartype">Passenger First Name</label>
                  <div class="input-group input-group-merge">
                    <input
                      v-model="form.contact_first"
                      placeholder="Enter First Name"
                      type="text"
                      id="ride-weight"
                      class="form-control"
                      :class="{
                        'is-invalid': submitted && $v.form.contact_first.$error,
                      }"
                    />
                    <div
                      v-if="submitted && $v.form.contact_first.$error"
                      class="invalid-feedback"
                    >
                      <div v-if="!$v.form.contact_first.required">
                        First Name is required
                      </div>
                    </div>
                  </div>
                </div>

                <div class="form-group mb-2">
                  <label for="ride-cartype">Passenger Last Name</label>
                  <div class="input-group input-group-merge">
                    <input
                      v-model="form.contact_last"
                      placeholder="Enter Last Name"
                      type="text"
                      id="ride-weight"
                      class="form-control"
                      :class="{
                        'is-invalid': submitted && $v.form.contact_last.$error,
                      }"
                    />
                    <div
                      v-if="submitted && $v.form.contact_last.$error"
                      class="invalid-feedback"
                    >
                      <div v-if="!$v.form.contact_last.required">
                        Last Name is required
                      </div>
                    </div>
                  </div>
                </div>

                <div class="form-group mb-2">
                  <label for="ride-contact">Passenger Phone Number</label>
                  <input
                    class="form-control"
                    v-model="form.contact_phone"
                    v-mask="'+1 ##########'"
                    placeholder="+1 ##########"
                    id="ride-contact"
                    :class="{
                      'is-invalid': submitted && $v.form.contact_phone.$error,
                    }"
                  />
                  <div
                    v-if="submitted && $v.form.contact_phone.$error"
                    class="invalid-feedback"
                  >
                    Contact number not valid
                  </div>
                </div>
              </template>

              <div class="form-group mb-2">
                <label for="ride-cartype">Car Type</label>
                <div class="input-group input-group-merge">
                  <multiselect
                    v-model="form.cartype"
                    :options="carTypes"
                    track-by="id"
                    label="label"
                    :multiple="false"
                    :allow-empty="false"
                    :maxHeight="240"
                    tag-placeholder=""
                    placeholder=""
                    class="w-100"
                    :class="{
                      'is-invalid': submitted && $v.form.cartype.$error,
                    }"
                  />
                  <div
                    v-if="submitted && $v.form.cartype.$error"
                    class="invalid-feedback"
                  >
                    <div v-if="!$v.form.cartype.required">
                      Car Type is required
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-6">
                  <div class="form-group mb-2">
                    <label>Gender</label>
                    <div class="input-group input-group-merge">
                      <multiselect
                        v-model="form.gender"
                        :options="genderTypes"
                        track-by="key"
                        label="label"
                        :multiple="false"
                        :allow-empty="false"
                        :maxHeight="240"
                        placeholder="Select gender"
                        class="w-100"
                        :class="{
                          'is-invalid': submitted && $v.form.gender.$error,
                        }"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group mb-2">
                    <label>Date of Birth</label>
                    <div class="input-group input-group-merge">
                      <date-picker
                        v-model="form.datebirth"
                        :lang="datepickerConfig"
                        value-type="YYYYMMDD"
                        default-value="1990-01-01"
                        format="MM-DD-YYYY"
                        placeholder="MM-DD-YYYY"
                        input-class="form-control"
                        :class="{
                          'is-invalid': submitted && $v.form.datebirth.$error,
                        }"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group mb-2">
                    <label class="text-nowrap" for="ride-weight"
                      >Weight (lb)</label
                    >
                    <div class="input-group input-group-merge">
                      <input
                        v-model.number="form.weight"
                        v-mask="wMask"
                        placeholder="Enter weight"
                        type="text"
                        id="ride-weight"
                        class="form-control"
                        :class="{
                          'is-invalid': submitted && $v.form.weight.$error,
                        }"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <!-- <div class="form-group mb-2">
                    <label for="ride-height">Height (ft, inches)</label>
                    <div class="input-group input-group-merge">
                      <input
                        v-model.number="form.height"
                        v-mask="wMask"
                        placeholder="Enter height"
                        type="text"
                        id="ride-height"
                        class="form-control"
                      />
                    </div>
                  </div> -->
                  <div class="row no-gutters">
                    <div class="col-6">
                      <div class="form-group mb-2 mr-1">
                        <label class="text-nowrap" for="ride-height_f"
                          >Height, ft</label
                        >
                        <div class="input-group input-group-merge">
                          <input
                            v-model="form.height_f"
                            v-mask="'#'"
                            placeholder=""
                            id="ride-height_f"
                            class="form-control form-control-no-err-ico"
                            :class="{
                              'is-invalid':
                                submitted && $v.form.height_f.$error,
                            }"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group mb-2 ml-1">
                        <label for="ride-height_i">inches</label>
                        <div class="input-group input-group-merge">
                          <input
                            v-model="form.height_i"
                            v-mask="'##'"
                            placeholder=""
                            id="ride-height_i"
                            class="form-control form-control-no-err-ico"
                            :class="{
                              'is-invalid':
                                submitted && $v.form.height_i.$error,
                            }"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group mt-1 mb-2">
                <div class="input-group">
                  <b-form-checkbox
                    id="ride-covtst"
                    v-model="form.covtst"
                    name="ride-covtst"
                    value="1"
                    unchecked-value="0"
                  >
                    COVID19 isolation required
                  </b-form-checkbox>
                </div>
              </div>

              <div class="form-group mb-2">
                <label for="ride-escort">Accompanied By (optional)</label>
                <div class="input-group input-group-merge">
                  <multiselect
                    v-model="form.escort"
                    :options="escortTypes"
                    track-by="id"
                    label="label"
                    :multiple="false"
                    :allow-empty="false"
                    :maxHeight="240"
                    tag-placeholder=""
                    placeholder=""
                    class="w-100"
                  />
                </div>
              </div>

              <div class="form-group mb-2">
                <label for="ride-contact">Contact Number (optional)</label>
                <input
                  class="form-control"
                  v-model="form.contact"
                  v-mask="'+1 ##########'"
                  placeholder="+1 ##########"
                  id="ride-contact"
                  :class="{ 'is-invalid': submitted && $v.form.contact.$error }"
                />
                <div
                  v-if="submitted && $v.form.contact.$error"
                  class="invalid-feedback"
                >
                  Contact number not valid
                </div>
              </div>

              <div class="form-group mb-2">
                <label for="ride-instruction">Special Instructions</label>
                <div class="input-group input-group-merge">
                  <input
                    v-model="form.instruction"
                    id="ride-instruction"
                    class="form-control"
                    placeholder="Give a special instruction if needed"
                    maxlength="60"
                    :class="{
                      'is-invalid': submitted && $v.form.instruction.$error,
                    }"
                  />
                  <div
                    v-if="submitted && $v.form.instruction.$error"
                    class="invalid-feedback"
                  >
                    <div v-if="!$v.form.instruction.required">
                      Special instructions is required
                    </div>
                    <div v-if="!$v.form.instruction.maxLength">
                      Special instructions max 60 symbols
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group mb-0">
                <label>Additional Service (optional)</label>
                <div class="input-group input-group-merge">
                  <b-form-checkbox
                    v-for="service in optServices"
                    :key="service.c"
                    :id="service.c"
                    v-model="form.services"
                    :value="service.c"
                    class="mr-3"
                  >
                    {{ service.t }}
                  </b-form-checkbox>
                </div>
              </div>

              <!-- <pre>form: {{ form }}</pre> -->
            </div>
          </div>
        </div>
        <div class="col-md-6 col-xl-8 mb-3 mb-md-0">
          <GmapMap
            key="map-route"
            :center="center"
            :zoom="5"
            map-type-id="terrain"
            style="width: 100%; height: 680px; margin-top: 25px"
          >
            <template v-if="waypointsLocation.length < 2">
              <GmapMarker
                v-for="w in waypointsLocation"
                :key="w.id"
                :position="w.position"
                :clickable="true"
                :draggable="false"
              />
            </template>
            <DirectionsRenderer
              v-if="showRoutes"
              :locations="waypointsLocation"
              travelMode="DRIVING"
              @update="updateRoute"
            />
          </GmapMap>

          <!-- <h1>Cached route restore example:</h1>
      <GmapMap
        key="map-route-cache"
        :center="center"
        :zoom="5"
        map-type-id="terrain"
        style="width: 100%; height: 680px; margin-top: 25px"
      >
        <DirectionsRenderer travelMode="DRIVING" :cachedRoute="cachedRoute" />
      </GmapMap>

      <button @click.prevent="showCachedRoute">showCachedRoute</button> -->

          <div class="mt-3 text-center text-md-left">
            <button
              class="btn btn-primary"
              @click.prevent="handleSubmit"
              :disabled="trySubmit || wait"
            >
              Next step
              <span class="material-symbols-rounded"> chevron_right </span>
            </button>
          </div>
        </div>

        <div class="col-12">
          <!-- <pre>waypointsLocation: {{ waypointsLocation }}</pre> -->
          <!-- <pre>waypointFields: {{ waypointFields }}</pre> -->
          <!-- <pre>optServices: {{ optServices }}</pre> -->
          <!-- <pre>form: {{ form }}</pre> -->
          <!-- <pre>mapFields: {{ mapFields }}</pre> -->
          <!-- <pre>escortTypes: {{ escortTypes }}</pre> -->
          <!-- <pre>genderTypes: {{ genderTypes }}</pre> -->
          <!-- <pre>cachedRoute: {{ cachedRoute }}</pre> -->
          <!-- <pre>route: {{ route }}</pre> -->
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main";
import { mapGetters } from "vuex";
import draggable from "vuedraggable";
import DirectionsRenderer from "@/components/DirectionsRenderer";
import { getCity } from "@/helpers";
import {
  genderTypes,
  escortTypes,
  carTypes,
  mapFields,
} from "@/components/data";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
import {
  required,
  minLength,
  maxLength,
  between,
} from "vuelidate/lib/validators";
import createNumberMask from "text-mask-addons/dist/createNumberMask";

export default {
  metaInfo() {
    return {
      title: this.$appConfig.title + " | Book a ride, step 1: Choose Route",
    };
  },
  // beforeRouteEnter(to, from, next) {
  //   // console.log("step-1, beforeRouteEnter... ");
  //   if (store.getters.authorized && store.getters.user.role === "c") {
  //     const ordersInWork = store.getters.client.currentorders.items.filter(
  //       (order) => order.status === 3 || order.status === 4
  //     );

  //     // if has orders in work redirect to - Current orders (/client/orders)
  //     if (ordersInWork.length) {
  //       router.push("/client/orders").catch(() => { });
  //     } else {
  //       if (Number(store.getters.step) === 2 && store.getters.routeData) {
  //         router.push("/ride/step-2").catch(() => { });
  //       } else if (
  //         store.getters.client?.currentorders?.items[0]?.status === 1
  //       ) {
  //         router.push("/ride/step-3").catch(() => { });
  //       } else {
  //         next();
  //       }
  //     }
  //   } else {
  //     next();
  //   }
  // },
  components: {
    Layout,
    draggable,
    DirectionsRenderer,
    Multiselect,
    DatePicker,
  },
  data() {
    return {
      msg: {
        has: false,
        type: "",
        text: "",
      },

      componentRestrictions: { country: "us" },

      // cachedRoute: null,

      maxWaypointFields: 7, // 5 + 2 points: start and end
      waypointsLocation: [],
      route: "",
      wait: false,

      center: { lat: 40.1966701, lng: -100.6248741 },
      mapFields: mapFields,

      showRoutes: true,
      waypointFields: [
        { id: "id-1", point: null },
        { id: "id-2", point: null },
      ],

      datepickerConfig: {
        formatLocale: {
          firstDayOfWeek: 1,
        },
        monthBeforeYear: false,
      },

      genderTypes: genderTypes,
      escortTypes: escortTypes,
      carTypes: carTypes,

      orderpreset: null,

      form: {
        whoride: 2, // 1 - me, 2 -Patient
        contact_first: "",
        contact_last: "",
        contact_phone: "",
        pickup_date: "",
        pickup_time: "",
        roundtrip: 0,
        cartype: carTypes[0],
        gender: genderTypes[0],
        datebirth: "",
        weight: "",
        height: "",
        height_f: "",
        height_i: "",
        covtst: 0, // 0 / 1
        escort: escortTypes[0],
        instruction: "",
        contact: "",
        services: [],
      },

      wMask: createNumberMask({
        prefix: "",
        allowDecimal: true,
        includeThousandsSeparator: false,
        allowNegative: false,
      }),

      submitted: false,
      trySubmit: true,
    };
  },
  validations() {
    return {
      form: this.formRules,
    };
  },
  created() {
    this.loadData();
    if (this.form.whoride === 1) {
      this.loadUserParam();
    }

    // show error
    if (this.$route.params.msg) {
      this.msg = this.$route.params.msg;
    }

    // has any order ?
    // if (this.client.currentorders?.total) {
    //   store.commit("modalWaitOrderAccept", true);
    // }
  },
  computed: {
    ...mapGetters([
      "authorized",
      "user",
      "opt",
      "routeData",
      "client",
      "routeData",
    ]),
    formRules() {
      let rules = {};

      if (this.form.whoride === 2) {
        rules.contact_first = {
          required,
        };

        rules.pickup_date = {
          required,
        };

        rules.pickup_time = {
          required,
        };

        rules.contact_last = {
          required,
        };

        rules.contact_phone = {
          required,
          minLength: minLength(13),
          maxLength: maxLength(13),
        };
      }

      rules.cartype = {
        required,
      };

      rules.gender = {
        valid: function (value) {
          return !!value.key;
        },
      };

      rules.weight = {
        valid: function (value) {
          return !!Number(value);
        },
      };

      rules.height_f = {
        required,
        between: between(1, 9),
      };

      rules.height_i = {
        between: between(0, 11),
      };

      rules.datebirth = {
        valid: function (value) {
          return !!Number(value);
        },
      };

      rules.instruction = {
        maxLength: maxLength(60),
      };

      rules.contact = {
        minLength: minLength(13),
        maxLength: maxLength(13),
      };

      return rules;
    },
    optServices() {
      const services = this.opt?.VENDOR_SERVICES || [];
      return services.filter((s) => s.g !== 2);
    },
    orderpresets() {
      return this.client.orderpreset || [];
    },
  },
  methods: {
    disabledDate(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      date.setHours(0, 0, 0, 0);
      return today > date || date > today.setDate(today.getDate() + 5);
    },
    disabledTime(date) {
      const today = new Date();
      const targetDate = new Date(this.form.pickup_date);

      today.setHours(0, 0, 0, 0);
      targetDate.setHours(0, 0, 0, 0);

      if (today.setHours(0, 0, 0, 0) == targetDate.setHours(0, 0, 0, 0)) {
        const minTime = new Date();
        minTime.setSeconds(0, 0);
        minTime.setHours(minTime.getHours() + 1);
        date.setSeconds(0, 0);
        return date < minTime;
      } else {
        return false;
      }
    },
    async loadData() {
      if (this.authorized) {
        await this.$store.dispatch("fetchClientOrderpreset");
      }

      // restore data and set to form
      this.initForm(this.routeData);
    },
    addWaypointField() {
      this.waypointFields.push({
        id: "id-" + new Date().getTime(),
        point: null,
      });
    },
    removeWaypointField(id) {
      this.waypointFields = this.waypointFields.filter((w) => w.id !== id);
    },
    setPlace(place, id) {
      // console.log("place: ", place);
      if (place.geometry) {
        this.waypointFields.find((w) => {
          if (w.id === id) {
            w.point = place;

            // center on marker
            this.center.lat = place.geometry.location.lat();
            this.center.lng = place.geometry.location.lng();
          }
        });
      }
    },
    resetPlace(id) {
      this.waypointFields.find((w) => {
        if (w.id === id) {
          // remove data
          w.point = null;
          // clear input
          this.$refs[`transitPoint-${id}`][0].$refs.input.value = "";
        }
      });
    },
    updateRoute(route) {
      this.route = route;
      this.wait = false;
    },
    setPlaceLetter(id) {
      const letters = ["A", "B", "C", "D", "E", "F", "G"];
      let index = "";

      this.waypointsLocation.filter((p, i) => {
        if (p.id === id) {
          index = i;
        }
      });

      return letters[index] || "";
    },
    loadUserParam() {
      // console.log("loadUserParam... user: ", this.user);
      if (this.user) {
        this.form.whoride = 1;

        this.form.gender =
          this.genderTypes.find((g) => g.key === this.user.gender) ||
          this.genderTypes[0];

        this.form.datebirth = String(this.user.datebirth); // 19901010 = Y-M-D
        this.form.weight = this.user.weight;

        this.form.height = this.user.height;
        this.form.height_f = parseInt(this.user.height);
        this.form.height_i = Math.round(
          (this.user.height - parseInt(this.user.height)) * 12
        );
      }
    },
    resetParam() {
      // console.log("resetParam...");
      // reset or restore from saved data
      let form = null;
      if (this.routeData) {
        form = { ...this.routeData.formData, ...this.routeData.addData };
      }

      // this.form.whoride = 2; // Someone else

      this.form.gender = form?.gender
        ? this.genderTypes.find((g) => g.key === form.gender)
        : this.genderTypes[0];
      this.form.datebirth = String(form?.datebirth) || "";
      this.form.weight = form?.weight || "";

      this.form.height = form?.height || "";

      const feet = parseInt(form.height);
      const inches = Math.round((form.height - parseInt(form.height)) * 12);

      this.form.height_f = feet || "";
      this.form.height_i = inches || "";
    },
    async handleSubmit() {
      // console.log("handleSubmit() ...");
      this.submitted = true;

      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      } else {
        this.trySubmit = true;

        // point from
        const pfrom_addr = this.waypointsLocation[0].location;
        const pfrom_city = this.waypointsLocation[0].city;
        const utc_offset = this.waypointsLocation[0].utc_offset_minutes;

        // point to
        const pto_addr =
          this.waypointsLocation[this.waypointsLocation.length - 1].location;
        const pto_city =
          this.waypointsLocation[this.waypointsLocation.length - 1].city;

        // all points: waypointsLocation
        const p_dat = {
          city: this.waypointsLocation[0].city,
          ets: this.waypointsLocation, // for recalc route - req and calc and render
          // cachedRoute: this.route, // result of calc route, need only for render without calc req
        };

        // Distance in miles, divided by meters
        let distance = 0;
        this.route.routes[0].legs.map((l) => (distance += l.distance.value));
        distance = Number(distance / 1609.34).toFixed(1);

        // Services
        const services = {};
        this.optServices.map((s) => {
          if (this.form.services.includes(s.c)) {
            services[s.c] = 1;
          } else {
            services[s.c] = 0;
          }
        });

        this.form.pickup_date.setHours(
          this.form.pickup_time.getHours(),
          this.form.pickup_time.getMinutes(),
          0
        );

        const addData = {
          pfrom_addr,
          pto_addr,
          p_dat,
          whoride: this.form.whoride,
          gender: this.form.gender.key,
          datebirth: Number(this.form.datebirth), // 19901010 = Y-M-D
          escort: this.form.escort.id,
          covtst: Number(this.form.covtst),
          contact: this.form.contact,
          orderAt: this.form.pickup_date.toISOString(),
          roundtrip: this.form.roundtrip,
          instruction: this.form.instruction,
        };

        if (this.form.whoride === 2) {
          addData.contact_first = this.form.contact_first;
          addData.contact_last = this.form.contact_last;
          addData.contact_phone = this.form.contact_phone;
        }

        const h =
          Number(this.form.height_f) + Number(this.form.height_i * 0.0833333);

        const formData = {
          pfrom_city,
          utc_offset,
          pto_city,
          distance: Number(distance),
          services,
          cartype: this.form.cartype.id,
          weight: this.form.weight ? Number(this.form.weight) : 0,
          orderAt: this.form.pickup_date.toISOString(),
          roundtrip: this.form.roundtrip,
          // height: this.form.height ? Number(this.form.height) : 0,
          height: h ? h : 0,
        };

        // console.log("Route form, formData: ", formData);
        // console.log("Route form, addData: ", addData);

        this.$store.commit("setRouteData", { formData, addData });
        // this.$store.commit("setStep", 2);
        this.$router.push("/ride/step-2");

        this.trySubmit = false;

        // console.log("formData: ", this.formData);
        // console.log("step: ", this.step);
      }
    },
    // showCachedRoute() {
    //   if (this.route) {
    //     console.log("showCachedRoute(), has route, cachedRoute = route");
    //     this.cachedRoute = null;

    //     this.$nextTick(() => {
    //       this.cachedRoute = this.route;
    //     });
    //   } else {
    //     console.log("showCachedRoute(), cachedRoute = NULL");
    //     this.cachedRoute = null;
    //   }
    // },
    initForm(data = null) {
      // console.log("initForm(), routeData: ", this.routeData);
      // console.log("initForm(), data: ", data);

      if (!data) {
        return false;
      }

      const form = { ...data.formData, ...data.addData };
      // console.log("initForm(), form: ", form);

      this.form.whoride = form.whoride; // ???
      this.form.cartype =
        this.carTypes.find((c) => Number(c.id) === Number(form.cartype)) ||
        this.carTypes[0];
      this.form.gender =
        this.genderTypes.find((g) => g.key === form.gender) ||
        this.genderTypes[0];
      this.form.datebirth = String(form.datebirth);
      this.form.weight = form.weight;

      this.form.height = form.height;
      this.form.height_f = parseInt(form.height);
      this.form.height_i = Math.round(
        (form.height - parseInt(form.height)) * 12
      );

      this.form.covtst = String(form.covtst);
      this.form.escort =
        this.escortTypes.find((e) => Number(e.id) === Number(form.escort)) ||
        this.escortTypes[0];
      this.form.contact = form.contact;
      this.form.instruction = form.instruction;
      this.form.services = Object.keys(form.services).filter(
        (s) => form.services[s]
      );
    },
  },
  watch: {
    waypointFields: {
      handler: function () {
        const newWaypointsLocation = [];

        this.waypointFields.map((w) => {
          if (w.point) {
            const city = getCity(w.point);

            newWaypointsLocation.push({
              id: w.id,
              location: w.point.formatted_address,
              utc_offset_minutes: city.utc_offset_minutes,
              city: city.name.join(", "),
              position: {
                lat: w.point.geometry.location.lat(),
                lng: w.point.geometry.location.lng(),
              },
              stopover: false,
              // point: w.point, // all place data
            });
          }
        });

        // check old and new, for stop re-requesting on modify, simple stringify enough
        if (
          JSON.stringify(this.waypointsLocation) !==
          JSON.stringify(newWaypointsLocation)
        ) {
          this.waypointsLocation = [];
          this.waypointsLocation = [...newWaypointsLocation];
          // console.log("waypointsLocation: ", this.waypointsLocation);

          if (this.waypointsLocation.length >= 2) {
            this.wait = true;
          }
        }
      },
      deep: true,
    },
    waypointsLocation: {
      handler: function () {
        if (this.waypointsLocation.length < 2) {
          this.showRoutes = false;
          this.trySubmit = true;

          this.$nextTick(() => {
            this.showRoutes = true;
          });
        } else {
          this.$nextTick(() => {
            this.trySubmit = false;
          });
        }
      },
    },
    orderpreset(preset) {
      // console.log("orderpreset, preset: ", preset);
      if (preset) {
        this.form.whoride = preset.whoride;
        this.form.datebirth = String(preset.datebirth);
        this.form.instruction = preset.instruction;
        this.form.contact = preset.contact;
        this.form.weight = preset.weight;

        this.form.height = preset.height;
        this.form.height_f = parseInt(preset.height);
        this.form.height_i = Math.round(
          (preset.height - parseInt(preset.height)) * 12
        );

        const cartype = this.carTypes.find(
          (c) => Number(c.id) === Number(preset.cartype)
        );
        if (cartype) {
          this.form.cartype = cartype;
        }

        const gender = this.genderTypes.find((g) => g.key === preset.gender);
        if (gender) {
          this.form.gender = gender;
        }

        const escort = this.escortTypes.find(
          (e) => Number(e.id) === Number(preset.escort)
        );
        if (escort) {
          this.form.escort = escort;
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.btn-ico-remove {
  color: #505655 !important;
  width: 26px;
  min-width: 26px;

  .material-symbols-rounded {
    font-size: 16px;
  }
}

.form-control-no-err-ico {
  background-image: none;
  padding-right: 0.9rem !important;
  text-align: center;
}
</style>
